import api from "@api";
export default {
    state: {
        isDialogOpen: false,
        semesterDto: {},

        semesterCols: [
            {
                label: "الترتيب",
                field: "order",
            },
            {
                label: "الفصل",
                field: "name",
            },
            {
                label: "عدد الوحدات المرتبطة",
                field: "unitCount",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        semesterList: [],
        rateDto: {
            answerPrice: "",
            sellPointRate: "",
            teacherRate: "",
        },
        colorDto: {
            colors: [],
        },
        settingDto: {
            answerPrice: "",
            sellPointRate: "",
            teacherRate: "",
            colors: [],
        },
        awsAccessDto: {
            api: "",
            accessToken: "",
        },
    },
    mutations: {
        SET_DETAIL_Semester(state, payload) {
            state.classDetailsDto = { ...payload };
        },
        Set_Semester_Dto(state, payload) {
            if (payload) state.semesterDto = { ...payload };
            else
                state.semesterDto = {
                    name: "",
                    order: 99,
                };
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        Add_Semester(state, payload) {
            if (state.settingDto.semesters.some((y) => y.id == payload.id)) {
                let dto = state.settingDto.semesters.find(
                    (ele) => ele.id == payload.id
                );
                Object.assign(dto, payload);
            } else {
                state.settingDto.semesters.unshift(payload);
            }
        },
        Delete_Semester(state, payload) {
            state.settingDto.semesters = state.settingDto.semesters.filter(
                (el) => !payload.includes(el.id)
            );
        },

        Get_Settings_Semester(state, payload) {
            state.semesterList = payload;
        },
        Get_Settings_List(state, payload) {
            state.settingDto = payload;
        },
        Get_Rates(state, payload) {
            state.rateDto = payload;
        },
        Get_Colors(state, payload) {
            state.colorDto = payload;
        },
        Get_Aws_Access(state, payload) {
            state.awsAccessDto = payload;
        },
        Get_Settings_Dto(state, payload) {
            if (payload) Object.assign(state.settingDto, payload);
            else
                state.settingDto = {
                    answerPrice: "",
                    sellPointRate: "",
                    teacherRate: "",
                    colors: [],
                };
        },
        Get_File_Link(state, payload) {
            state.settingDto = payload;
        },
        Set_Default_Color(state, payload) {
            state.settingDto = payload;
        },
    },
    actions: {
        getSemesterById({ commit }, id) {
            return new Promise(res => {
                api.get(`Semester/Dash/GetById?id=${id}`, ({ data }) => {
                    commit("Set_Semester_Dto", data);
                    res(data);
                });
            });
        },

        deleteSemester({ commit }, id) {
            api.delete(
                `Semester/Dash/Delete?id=${id}`,
                ({ data }) => {
                    commit("Delete_Semester", data);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الفصل المحددة",
                    success: "تم حذف الفصل بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
            );
        },
        deleteSemesterAll({ commit }, ids) {
            api.delete(
                "Semester/Dash/DeleteRange",
                () => {
                    commit("Delete_Semester", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الفصل  المحددة",
                    success: "تم حذف الفصل  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        modifySemester({ commit }, payload) {
            api.post(
                "Semester/Dash/Modify",
                payload,
                (res) => {
                    commit("Add_Semester", res.data);
                },
                {
                    confirm: "هل تريد بالتأكيد التعديل ",
                    success: "تم  بنجاح",
                    error: "فشل .. يرجى إعادة المحاولة",
                }
            );
        },
        addSemester({ commit }, payload) {
            api.post(
                "Semester/Dash/Add",
                payload,
                (res) => {
                    commit("Add_Semester", res.data);
                },
                {
                    success: "تم الإضافة بنجاح",
                    error: "فشلت إضافة ",
                }
            );
        },

        getSettingSemester({ commit }) {
            api.get("Semester/Dash/GetAll", ({ data }) => {
                commit("Get_Settings_Semester", data);
            });
        },
        getSettings({ commit }) {
            api.get("Setting/Dash/Get", ({ data }) => {
                commit("Get_Settings_List", data);
            });
        },
        getRates({ commit }) {
            api.get("Setting/Dash/GetRates", ({ data }) => {
                commit("Get_Rates", data);
            });
        },
        getColors({ commit }) {
            api.get("Setting/Dash/GetColors", ({ data }) => {
                commit("Get_Colors", data);
            });
        },
        getAwsAccess({ commit }) {
            api.get("Setting/GetAwsAccess", ({ data }) => {
                commit("Get_Aws_Access", data);
            });
        },
        getFileLink({ commit }, url) {
            api.get(`Setting/GetFileLink?url=${url}`, ({ data }) => {
                commit("Get_File_Link", data);
            });
        },
        updateSettings({ commit }, payload) {
            api.post(
                "Setting/Dash/Modify",
                payload,
                (res) => {
                    commit("Get_Settings_Dto", res.data);
                },
                {
                    confirm: "هل تريد بالتأكيد التعديل ",
                    success: "تم التعديل بنجاح",
                    error: "فشل التعديل.. يرجى إعادة المحاولة",
                }
            );
        },
        setDefaultColor({ commit }, payload) {
            api.post(
                "Setting/Dash/RestColorsToDefault",
                payload,
                (res) => {
                    commit("Set_Default_Color", res.data);
                },
                {
                    confirm: "هل تريد بالتأكيد الرجوع للالوان الافتراضية ",
                    success: "تم العودة للالوان الافتراضية  ",
                    error: "فشل العودة للالوان الافتراضية.. يرجى إعادة المحاولة",
                }
            );
        },
    },
};
