// import countries from "./area-settings/countries"
import settings from "@admin/settings";
import notifications from "@admin/notifications";
import contactUs from "@admin/contactUs";
import dashboardUsers from "@admin/dashboardUsers";
import classes from "@admin/classes";
import teachers from "@admin/teachers";
import adverts from "@admin/adverts";
import subjects from "@admin/subjects";
import lessons from "@admin/lessons";
import units from "@admin/units";
import students from "@admin/students";
import questionsBank from "@admin/questionsBank";
import sellPoints from "@admin/sellPoints";
import chapter from "@admin/chapter";
import questionsAnswerer from "@admin/questionsAnswerer";
import activationCode from "@admin/activationCode";
import invoiceGuide from "@admin/invoice/invoiceGuide";
import invoiceSellPoint from "@admin/invoice/invoiceSellPoint";
import invoiceTeacher from "@admin/invoice/invoiceTeacher";
import home from "@admin/home";
export default {
    // countries,
    home,
    invoiceTeacher,
    invoiceGuide,
    invoiceSellPoint,
    settings,
    activationCode,
    questionsAnswerer,
    sellPoints,
    chapter,
    notifications,
    dashboardUsers,
    classes,
    teachers,
    adverts,
    lessons,
    units,
    subjects,
    contactUs,
    students,
    questionsBank
};
