<template>
    <div class="d-flex justify-content-end activator">
        <b-input-group
            class="input-group-merge"
            style="margin: auto; margin-left: 20px"
            v-if="!!placeholder"
        >
            <b-form-input
                @input="search"
                type="search"
                :placeholder="placeholder"
            />
            <b-input-group-append is-text>
                <unicon width="16" height="16" fill="gray" name="search" />
            </b-input-group-append>
        </b-input-group>
        <slot>
            <b-button
                v-if="!!btnText"
                size="sm"
                :variant="noIcon ? 'outline-primary' : 'primary'"
                class="text-nowrap d-flex align-items-center"
                :class="{ 'activator__button--isReversed': noIcon }"
                @click="ok"
            >
                {{ btnText }}
                <unicon
                    v-if="!noIcon"
                    class="ml-1"
                    name="plus"
                    width="18"
                    height="18"
                    fill="#fff"
                />
            </b-button>
        </slot>
    </div>
</template>
<script>
import { BInputGroup, BFormInput, BInputGroupAppend } from "bootstrap-vue";
export default {
    components: {
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
    },
    props: {
        btnText: String,
        placeholder: String,
        noIcon: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        search(e) {
            this.$emit("search", e);
        },
        ok() {
            this.$emit("ok");
        },
    },
};
</script>

<style lang="scss" scoped>
.activator {
    &__button {
        &--isReversed {
            order: -1;
            margin-right: 1rem;
        }
    }
}
</style>
