import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";
import router from "@/router";


export default {
    state: {
        sellpoints: [],
        sellpointsDetails: [],
        sellpointUnpaid:[],
        sellPointCols: [
            {
                label: "نقطة البيع",
                field: "name",
            },
            {
                label: "عدد الرموز",
                field: "codeCount",
            },
            {
                label: "تاريخ آخر مطابقة",
                field: "lastAccordanceDate",
            },
            {
                label: "صافي االارباح ",
                field: "netProfit",
            },
            {
                label: "رصيد نقطة البيع ",
                field: "sellPointPaid",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        DetailSellCols: [
            {
                label: "رقم الفاتورة ",
                field: "serialNumber",
            },
            {
                label: " التاريخ",
                field: "date",
            },
            {
                label: "  عدد الرموز",
                field: "codeCount",
            },
            {
                label: " قيمة الفاتورة ",
                field: "paid",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
    },
    getters:{
        sell({ sellpointsDetails }, getter , { filter }) {
            return sellpointsDetails.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        },
        sellPointList(state) {
            return state.sellpoints.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        sellPointDetailsList(state) {
            return state.sellpointsDetails.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        sellPointUnpaidList(state) {
            return state.sellpointUnpaid.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
    },
    mutations: {
        Get_SellPoints_List(state, payload) {
            state.sellpoints = payload 
        },
        Get_Details_SellPoint_List(state, payload) {
            state.sellpointsDetails = payload
        },
        Get_Unpaid_SellPoint_List(state, payload) {
            state.sellpointUnpaid = payload 
        },
    },
    actions: {
        getSellPointsList({ commit }) {
            api.get("Invoice/Dash/GetAllSellPoint", ({ data }) => {
                commit("Get_SellPoints_List", data)
            });
        },
        getDetailsSellPointList({ commit }, id) {
            api.get(`Invoice/Dash/GetDetailsSellPoint?id=${id}`, ({ data }) => {
                commit("Get_Details_SellPoint_List", data)
            });
        },
        getUnpaidSellPointList({ commit }, id) {
            return new Promise(resolve => {
                api.get(`Invoice/Dash/GetUnpaidBySellPointId?id=${id}`,
                ({ data }) => {
                    commit("Get_Unpaid_SellPoint_List", data),
                    resolve(data)
                },
                );
            })
        },
        addSellpoint(_, payload) {
            return new Promise(resolve => {
                api.post(
                    "Invoice/Dash/AddInvoiceToSellPoint",
                    payload,
                    ({ data }) => {
                        resolve(data)
                        router.push(router.go(-1));
                    },
                    {
                        success: "تم إضافة الفاتورة بنجاح",
                        error: "فشل إضافة الفاتورة",
                    }
                );
            })
            
        },
        getSellPointDetails(_, id) {
            return new Promise(resolve => {
                api.get(`Invoice/Dash/GetDetailsInvoiceSellPoint?id=${id}`,
                    ({ data }) => {
                        resolve(data)
                    }
                );
            })
           
        },
    },
};