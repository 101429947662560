import { getSearchResultOnRow } from "@Ekcore/util";
import api from "@/EK-core/api";
import router from "@/router";

export default {
    state: {
        questions: [],
        questionsDto: {
            count: "",
            questions: []
        },
        quizeOptions: [],
        questionId: "",
        questionsPagination: {
            pageNumber: 1,
            pageSize: 6
        },
        quizDto: {
            quizId: "",
            unitId: "",
            order: ""
        },
        questionFilter: {
            subject: "",
            quize: ""
        },
        mediaTypeEnum: {
            1: "مقطع فيديو",
            2: "صورة",
            3: "تسجيل صوت",
            4: "ملف",
            5: "نص"
        },
        mediaOptions: [
            { id: 1, name: " فيديو " },
            { id: 2, name: "صورة" },
            { id: 3, name: "صوت" },
            { id: 4, name: "مستند" }
        ],
        questionCols: [
            {
                field: "order",
                type: "number",
                label: " رقم السؤال"
            },
            {
                field: "content",
                label: " السؤال"
            },
            {
                field: "quizName",
                sorable: true,
                label: " اسم الاختبار "
            },
            {
                field: "dateCreated",
                sorable: true,
                label: "تاريخ الاضافة"
            },
            {
                field: "details",
                label: "تفاصيل "
            }
        ],
        importQuestionDto: {}

        // coordianteChartList: []
    },
    getters: {
        quizDto: state => state.quizDto,
        questionsList(state) {
            return state.questions.filter(serv => {
                return getSearchResultOnRow(state, serv);
            });
        },
        resultQuestionBank(state, getters, { filter }) {
            let query = filter.searchDto.query;
            return state.questionsDto.questions.filter(el => {
                return (
                    !query ||
                    el.content.indexOf(query) != -1 ||
                    el.order == query
                );
            });
        }
    },
    mutations: {
        Import_Question(state, payload) {
            state.importQuestionDto = payload
            console.log('test1', payload)
        },
        //set main question , add , reset
        SET_BY_ID_QUESTION(state, payload) {
            state.questionsDto = payload;
        },
        SET_ID_QUESTION(state, payload) {
            state.quizDto.quizId = payload;
            // console.log(payload);
        },
        // ADD_QUESTIONS(state, payload) {
        //     state.mainQuestins.unshift(payload);
        // },
        RESET_QUIZ_DTO(state) {
            state.quizDto = { quizId: "", unitId: "", order: "" };
        },
        SET_QUIZ_DTO(state, payload) {
            console.log("SET_QUEZ_DTO", payload);
            state.quizDto = {
                ...payload,
                name: payload.quizName ? payload.quizName : payload.name,
                order: payload.order ? payload.order : payload.questionOrder,
                questionOrder: payload.order
                    ? payload.order
                    : payload.questionOrder
            };
        },
        SET_QUESTIONS_QUIZE(state, payload) {
            state.questions = payload;
        },
        SET_QUIZ_OPTIONS(state, payload) {
            state.quizeOptions = payload;
        },
        Delete_Questions(state, ids) {
            state.questions = state.questions.filter(
                el => !ids.includes(el.id)
            );
        },
        Delete_Question(state, id) {
            state.questions.splice(
                state.questions.findIndex(item => item.id == id),
                1
            );
        },
        Add_Answer(state) {
            state.questionsDto.answerDtos.push({
                content: "",
                isCorrect: false
            });
        },
        Delete_Questions_Quiz(state, ids) {
            state.questions = state.questions.filter(
                el => !ids.includes(el.id)
            );
        }

        // ADD_COORDINATECHART(state, payload) {
        //     state.coordianteChartList.push(payload);
        //     console.log("payload in mutation", payload);
        // }
        // UPDATE_COORDINATECHART(state, payload) {
        //     state.coordianteChartDto = { ...payload };
        // },
        // Set_ID_COORDINATECHART(state, payload) {
        //     state.coordianteChartDto = { ...payload };
        // }
    },
    actions: {
        importQuestion(_, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/ImportQuestion",
                    payload,
                    ({ data }) => {
                        resolve(data)
                    },
                    {
                        success: "تم الاستيراد  من اكسل",
                        error: "فشل الاستيراد"
                    }
                );
            });
        },
        getAllQuizQuestions(
            { commit },
            { PageNumber, PageSize, QuizId, SubjectId }
        ) {
            api.get(
                `QuestionBank/Dash/GetAll?PageNum=${PageNumber}&PageSize=${PageSize}&QuizId=${QuizId}&SubjectId=${SubjectId ? SubjectId : ""
                }`,
                ({ data }) => {
                    commit("SET_BY_ID_QUESTION", data);
                }
            );
        },

        getByIdQuestion({ commit }, id) {
            return new Promise(resolve => {
                api.get(
                    `QuestionBank/Dash/GetByIdQuestion?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        commit("SET_QUIZ_DTO", {
                            ...data,
                            name: data.quizName | data.name
                        });
                    }
                );
            });
        },

        addMainQuestion(__, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/AddQuestion",
                    payload,
                    ({ data }) => {
                        resolve(data)
                    },
                    {
                        success: "تم إضافة السؤال بنجاح",
                        error: "فشل إضافة السؤال"
                    }
                );
            });
        },

        updateMainQuestion(__, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/ModifyQuestion",
                    payload,
                    ({ data }) => {
                        resolve(data), router.push(router.go(-1));
                    },
                    {
                        success: "تم تعديل السؤال بنجاح",
                        error: "فشل التعديل ..يرجى اعادة المحاولة "
                    }
                );
            });
        },
        getByIdWritten({ commit }, id) {
            return new Promise(resolve => {
                api.get(
                    `QuestionBank/Dash/GetByIdWrittenQuestion?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        commit("SET_QUIZ_DTO", {
                            ...data,
                            name: data.quizName | data.name
                        });
                    }
                );
            });
        },
        addWrittenQuestion(__, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/AddWrittenQuestion",
                    payload,
                    ({ data }) => {
                        resolve(data)
                    },
                    {
                        success: "تم إضافة السؤال بنجاح",
                        error: "فشل إضافة السؤال"
                    }
                );
            });
        },
        updateWrittenQuestion(__, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/ModifyWrittenQuestion",
                    payload,
                    ({ data }) => {
                        resolve(data), router.push(router.go(-1));
                    },
                    {
                        success: "تم تعديل السؤال بنجاح",
                        error: "فشل التعديل ..يرجى اعادة المحاولة "
                    }
                );
            });
        },
        getByIdTrueFalse({ commit }, id) {
            return new Promise(resolve => {
                api.get(
                    `QuestionBank/Dash/getByIdTrueFalse?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        commit("SET_QUIZ_DTO", {
                            ...data,
                            name: data.quizName | data.name
                        });
                    }
                );
            });
        },
        addTrueFalse(_, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/AddTrueFalse",
                    payload,
                    ({ data }) => {
                        resolve(data)
                    },
                    {
                        success: "تم إضافة السؤال بنجاح",
                        error: "فشل إضافة السؤال"
                    }
                );
            });
        },
        updateTrueFalse(_, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/ModifyTrueFalse",
                    payload,
                    ({ data }) => {
                        resolve(data), router.push(router.go(-1));
                    },
                    {
                        success: "تم تعديل السؤال بنجاح",
                        error: "فشل التعديل ..يرجى اعادة المحاولة "
                    }
                );
            });
        },

        getByIdTable({ commit }, id) {
            return new Promise(resolve => {
                api.get(
                    `QuestionBank/Dash/GetByIdTable?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        commit("SET_QUIZ_DTO", {
                            ...data,
                            name: data.quizName | data.name
                        });
                    }
                );
            });
        },
        addTable(_, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/AddTable",
                    payload,
                    ({ data }) => {
                        resolve(data)
                    },
                    {
                        success: "تم إضافة السؤال بنجاح",
                        error: "فشل إضافة السؤال"
                    }
                );
            });
        },
        updateTable(_, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/ModifyTable",
                    payload,
                    ({ data }) => {
                        resolve(data), router.push(router.go(-1));
                    },
                    {
                        success: "تم تعديل السؤال بنجاح",
                        error: "فشل التعديل ..يرجى اعادة المحاولة "
                    }
                );
            });
        },
        getQuizes({ commit }, id) {
            api.get(
                `Syllabus/Dash/GetQuizLessonsId?subjectId=${id}`,
                ({ data }) => {
                    commit("SET_QUIZ_OPTIONS", data);
                }
            );
        },
        addQuestionQuizLesson({ commit }, payload) {
            return new Promise(resolve => {
                api.post(
                    "Syllabus/Dash/AddQuestionQuizLesson",
                    payload,
                    ({ data }) => {
                        resolve(data);
                        commit("add_Question_Quiz_Lesson", data);
                    },
                    {
                        success: "تم إضافة السؤال بنجاح",
                        error: "فشل إضافة السؤال"
                    }
                );
            });
        },
        addQuestionAnswer({ commit }) {
            commit("Add_Answer");
        },
        updateCoordinateChart(__, payload) {
            return new Promise(resolve => {
                api.post(
                    "QuestionBank/Dash/ModifyCoordinateChart",
                    payload,
                    ({ data }) => {
                        // this.$store.commit("UPDATE_COORDINATECHART", data);
                        resolve(data);
                        router.go(-1);
                    },
                    {
                        success: "تم تعديل السؤال بنجاح",
                        error: "فشل التعديل ..يرجى اعادة المحاولة "
                    }
                );
            });
        },

        getByIdCoordinateChart({ commit }, id) {
            return new Promise(resolve => {
                api.get(
                    `QuestionBank/Dash/GetByIdCoordinateChart?id=${id}`,
                    ({ data }) => {
                        resolve(data);
                        commit("SET_QUIZ_DTO", {
                            ...data,
                            name: data.quizName ? data.quizName : data.name
                        });
                        // console.log("get id chart in action");
                    }
                );
            });
        },
        addCoordinateChart(__, payload) {
            return new Promise(resolve => {
                api.post(
                    "QuestionBank/Dash/AddCoordinateChart",
                    payload,
                    ({ data }) => {
                        resolve(data);
                    },
                    {
                        success: "تم اضافة الرسم البياني بنجاح",
                        error: "فشلت اضافة الرسم البياني بنجاح"
                    }
                );
            });
        },
        addMatching(__, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/AddMatching",
                    payload,
                    ({ data }) => {
                        resolve(data);
                    },
                    {
                        success: "تم اضافة التوصيل بنجاح",
                        error: "فشلت اضافة التوصيل بنجاح"
                    }
                );
            });
        },
        getByIdMatching({ commit }, id) {
            return new Promise(resolve => {
                api.get(
                    `QuestionBank/Dash/GetByIdMatching?id=${id}`,
                    ({ data }) => {
                        commit("SET_QUIZ_DTO", {
                            ...data,
                            name: data.quizName | data.name
                        });
                        resolve(data);
                    }
                );
            });
        },
        updateMatching(__, payload) {
            return new Promise(resolve => {
                api.form.post(
                    "QuestionBank/Dash/ModifyMatching",
                    payload,
                    ({ data }) => {
                        resolve(data);
                        router.go(-1);
                    },
                    {
                        success: "تم تعديل التوصيل بنجاح",
                        error: "فشل التعديل التوصيل بنجاح"
                    }
                );
            });
        },
        deleteQuestion({ commit }, id) {
            console.log('ffff')
            api.delete(
                `QuestionBank/Dash/Delete?id=${id}`,
                () => {
                    commit("Delete_Question", id);
                    router.go(-1)
                },
                {
                    confirm: " هل تريد بالتأكيد حذف السؤال",
                    success: "تم حذف السؤال بنجاح",
                    error: "فشل حذف السؤال ..يرجى اعادة المحاولة "
                }
            );
        },
        deleteQuestions({ commit }, ids) {
            api.delete(
                "QuestionBank/Dash/DeleteRange",
                () => {
                    commit("Delete_Questions", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاسئلة المحددة",
                    success: "تم حذف الاسئلة بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
            // console.log(ids);
        }
    }
};
