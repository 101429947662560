<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view v-show="!$store.getters['app/isLoading']" />
    </component>

    <transition>
      <loading
        v-show="$store.getters['app/isLoading']"
        :percentCompleted="$store.getters['app/percentCompleted']"
      />
    </transition>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
// import { gsap } from "gsap";
import { useWindowSize, useCssVar } from "@vueuse/core";
import store from "@/store";
import loading from "@core/components/loading/loading.vue";
const LayoutVertical = () => import("@/app/admin/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/app/admin/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/app/admin/layouts/full/LayoutFull.vue");

export default {
  components: {
    loading,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  methods: {
    // onEnter(el, done) {
    //   gsap.from(el, { duration: 0.5, opacity: 0 }).eventCallback("onComplete", done);
    // },
    // onLeave(el, done) {
    //   gsap.to(el, { duration: 0.5, opacity: 0 }).eventCallback("onComplete", done);
    // },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (
        typeof this.$route.meta == "function" &&
        this.$route.meta(this.$route).layout === "vertical"
      )
        return "layout-vertical";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(
          0,
          -2
        )
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
};
</script>
