<template>
  <ValidationProvider :vid="vid" :name="name" :rules="faildRules">
    <b-form-group
      :label-cols="labelCols"
      slot-scope="{ valid, errors, validated, touched }"
      v-bind="$attrs"
      class="ek-form-group"
      :label-for="'label-for-text-' + id"
    >
      <b-input-group class="rounded">
        <flat-pickr
          v-bind="$attrs"
          :id="'label-for-picker-range-' + id"
          @on-change="onChange"
          v-model="innerVal"
          @change="$emit('change', $event)"
          :placeholder="placeholder"
          class="form-control text-left"
          :state="faildRules ? (errors[0] ? false : valid ? true : null) : null"
          dir="ltr"
          :config="config"
          :class="{
            'border-danger': !valid && validated && touched,
          }"
        />
        <template #append>
          <b-input-group-text>
            <unicon name="calendar-alt" width="18" height="18" fill="#B9B9C3" />
          </b-input-group-text>
        </template>
      </b-input-group>
      <small class="text-danger" v-if="!valid && validated && touched">
        {{ errors[0] }}
      </small>
    </b-form-group>
  </ValidationProvider>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { BFormGroup } from "bootstrap-vue";
import { ValidationProvider, localize } from "vee-validate";
import ar from "vee-validate/dist/locale/ar.json";
import {
  required,
  email,
  confirmed,
  regex,
  url,
  credit,
  password,
  between,
  alpha,
  integer,
  digits,
  alphaDash,
  alphaNum,
  length,
  no_spaces,
  positive,
  minVal,
  maxVal,
  english_letters_numbers,
  mobile,
  phone,
  time,
  username,
} from "@validations";
export default {
  components: {
    ValidationProvider,
    flatPickr,
    // bootstrap vue
    BFormGroup,
  },
  props: {
    placeholder: String,
    type: String,
    name: String,
    max: Date,
    min: Date,
    vid: String,
    rules: {
      type: Array,
      default: () => [],
    },
    labelCols: {
      type: Number,
      default: () => 12,
    },
    range: { type: Boolean, default: false },
    value: {
      type: null,
    },
    clock: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.value) {
      this.innerVal = this.value;
    }
  },

  created() {
    this.config.mode = this.range ? "range" : "single";
    this.config.enableTime = this.clock;
    this.config.minDate = this.min;
    this.config.maxDate = this.max;
  },

  setup(props) {
    let faildRules = "";
    let fields = {};
    props.rules.forEach((rule) => {
      faildRules += rule.type + "|";
      fields[rule.type.split(":")[0]] = rule.message;
    });
    faildRules = faildRules.slice(0, faildRules.length - 1);
    localize("ar", {
      messages: ar.messages,
      fields: {
        [props.name]: fields,
      },
    });
    return { faildRules };
  },

  data: () => ({
    innerVal: "",
    id: Math.random() * 100000,
    config: {
      allowInput: true,
      mode: "single",
      enableTime: true,
      min: "",
      max: "",
    },

    required,
    email,
    confirmed,
    regex,
    url,
    credit,
    password,
    between,
    alpha,
    integer,
    digits,
    alphaDash,
    alphaNum,
    length,
    no_spaces,
    positive,
    minVal,
    maxVal,
    english_letters_numbers,
    mobile,
    phone,
    time,
    username,
  }),
  methods: {
    onChange(v) {
      this.$emit("onChange", v);
    },
  },
  watch: {
    innerVal(v) {
      if (v) {
        if (!this.range) {
          this.$emit("input", this.type == "number" ? +v : v ? v.trim() : "");
        } else {
          if (!Array.isArray(v) && v.length > 10) this.$emit("input", v?.trim());
        }
      } else this.$emit("input", "");
    },
    value(v) {
      this.innerVal = v;
    },
  },
};
</script>
<style lang="scss">
.form-control:focus {
  background-color: transparent;
}
</style>
