import api from "@api";
import router from "@/router";
import { nullGuid } from "@Ekcore/util/global.js";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        teachers: [],
        teacherDto: {
            id: nullGuid,
            imageUrl: null,
            phoneNumber: "",
            description: "",
            email: "",
            name: "",
            rate: "",
            password: "",
            newPassword: "",
            subCount: "",
            isBlocked: "",
            unitsCount: 0,
            units: [
                {
                    id: "",
                    className: "",
                    semester: "",
                    price: "",
                    dateCreated: "",
                    subCount: 0,
                },
            ],
        },
        statusTeacher: [
            { value: false, text: "فعال", color: "success" },
            { value: true, text: "غير فعال", color: "danger" },
        ],
        TeacherCols: [
            {
                label: "اسم الاستاذ",
                field: "name",
            },
            {
                label: "تاريخ الاشتراك",
                field: "dateCreated",
            },
            {
                label: "عدد الوحدات",
                field: "unitsCount",
            },
            {
                label: "حالة الحساب",
                field: "isBlocked",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        HeaderDetails: [
            {
                label: "اسم الوحدة ",
                field: "name",
            },
            {
                label: "الصف ",
                field: "className",
            },
            {
                label: "الفصل ",
                field: "semester",
            },
            {
                label: "اسم المادة ",
                field: "subjectName",
            },
            {
                label: "السعر ",
                field: "price",
            },
            {
                label: "تاريخ الاضافة",
                field: "dateCreated",
            },
            {
                label: "عدد الاشتراكات",
                field: "subCount",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        filterDto: {},
        searchDto: {
            keys: [],
            query: "",
        },
        tempFiles: [],
    },
    getters: {
        teacherList({ teachers }, getter, { filter }) {
            return teachers.filter((el) => {
                return getSearchResultOnRow(filter, el);
            });
        },
    },
    mutations: {
        Get_Teachers_List(state, payload) {
            state.teachers = payload;
        },
        Add_teachers(state, payload) {
            state.teachers.unshift(payload);
        },
        Get_Details_Teacher(state, payload) {
            state.teacherDto = { ...payload };
        },
        Update_Teacher(state, payload) {
            state.teacherDto = { ...payload };
        },
        Delete_Teacher(state, id) {
            state.teachers.splice(
                state.teachers.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Teachers(state, ids) {
            state.teachers = state.teachers.filter(
                (el) => !ids.includes(el.id)
            );
        },
        Set_Teacher_Dto(state, payload) {
            if (payload) Object.assign(state.teacherDto, payload);
            else {
                state.teacherDto = {
                    id: nullGuid,
                    phoneNumber: "",
                    email: "",
                    name: "",
                    rate: "",
                    password: "",
                    description: "",
                    Image: "",
                };
            }
        },
        ISBLOCK_Teacher(state, payload) {
            state.teacherDto.isBlocked = payload;
        },
    },
    actions: {
        getTeachersList({ commit }) {
            api.get("Teacher/Dash/GetAll", ({ data }) => {
                commit("Get_Teachers_List", data);
            });
        },
        addTeachers({ commit }, payload) {
            // Create a new FormData object
            let formData = new FormData();

            // Append each property in the payload to the FormData object
            for (let key in payload) {
                formData.append(key, payload[key]);
            }

            // Send the FormData using the api.post method
            api.post(
                "Teacher/Dash/Add",
                formData,
                ({ data }) => {
                    commit("Add_teachers", data);
                    commit("Reset_Teacher_Dto");
                },
                {
                    success: "تم إضافة الاستاذ بنجاح",
                    error: "فشل إضافة الاستاذ",
                }
            );
        },
        getTeacherDetails({ commit }, id) {
            api.get(`Teacher/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Teacher", data);
            });
        },
        updateTeacher({ commit }, payload) {
            // Create a new FormData object
            let formData = new FormData();

            // Append each property in the payload to the FormData object
            for (let key in payload) {
                formData.append(key, payload[key]);
            }

            // Send the FormData using the api.post method
            api.post(
                "Teacher/Dash/Modify",
                formData,
                ({ data }) => {
                    commit("Update_Teacher", data);
                    router.push("/admin/teachers");
                },
                {
                    success: "تم التعديل بنجاح",
                    error: "فشل التعديل",
                }
            );
        },
        deleteTeacher({ commit }, id) {
            api.delete(
                `Teacher/Dash/Delete?id=${id}`,
                () => {
                    commit("Delete_Teacher", id);
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الاستاذ",
                    success: "تم حذف الاستاذ بنجاح",
                    error: "فشل حذف الاستاذ ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteTeachers({ commit }, ids) {
            api.delete(
                "Teacher/Dash/DeleteRange",
                () => {
                    commit("Delete_Teachers", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاساتذة المحددة",
                    success: "تم حذف الاساتذة بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        blockTeacher({ commit }, id) {
            api.post(
                `User/Dash/Block?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_Teacher", true);
                },
                {
                    success: "تم حظرالاستاذ بنجاح",
                    error: "فشل الحظر  ..يرجى اعادة المحاولة ",
                }
            );
        },
        unblockTeacher({ commit }, id) {
            api.post(
                `User/Dash/UnBlock?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_Teacher", false);
                },
                {
                    success: "تم الغاء حظر الاستاذ بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة ",
                }
            );
        },
    },
};
