<template>
  <div>
    <b-modal
      :hide-header="noHeader"
      :hide-footer="noFooter"
      @hide="closeEvent"
      @show="showEvent"
      v-bind="$attrs"
      :size="size"
      :no-close-on-backdrop="noDismiss"
      scrollable
      centered
      :content-class="noFill ? '' : 'h-100'"
      hide-header-close
      id="ek-modal"
      class="ek-modal"
      ref="ek-modal"
    >
      <template #modal-header v-if="!noHeader">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h4 class="m-0">{{ title }}</h4>
          <b-button
            variant="outline-danger"
            class="px-25 py-50"
            @click="$refs['ek-modal'].hide()"
            ><unicon name="times" height="15" class="svg-danger"></unicon
          ></b-button>
        </div>
      </template>
      <template #modal-footer v-if="!noFooter">
        <div class="d-flex w-100 align-items-center justify-content-between">
          <div class="d-flex" style="gap: 1rem">
            <b-button variant="primary" @click="setEvent"
              >{{ editMode ? $t("ek.edit") : $t("ek.add") }}
              <unicon
                class="svg-light"
                height="15"
                :name="editMode ? 'pen' : 'plus'"
              ></unicon>
            </b-button>
            <b-button variant="outline-primary" @click="$refs['ek-modal'].hide()">
              الغاء</b-button
            >
          </div>

          <b-button
            v-if="!noDelete && editMode"
            @click="deleteEvent"
            variant="outline-danger"
            class="d-flex align-items-center"
          >
            <span>
              {{ $t("ek.delete") }}
            </span>
            <unicon class="svg-danger" height="16px" name="trash-alt"></unicon>
          </b-button>
        </div>
      </template>
      <slot v-bind="{ isEdit: editMode }"> </slot>
    </b-modal>
    <b-button v-b-modal="'ek-modal'" variant="primary" v-if="btnText"
      >{{ btnText }} <unicon :name="btnIcon" v-if="btnIcon"></unicon
    ></b-button>

    <b-button
      v-if="floatBtn"
      class="float-btn rounded-circle p-50"
      variant="primary"
      v-b-modal="'ek-modal'"
      ><unicon name="plus" class="svg-light"></unicon
    ></b-button>
  </div>
</template>

<script>
export default {
  props: {
    noDismiss: { type: Boolean, default: false },
    noDelete: { type: Boolean, default: false },
    floatBtn: { type: Boolean, default: false },
    noHeader: { type: Boolean, default: false },
    noFooter: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false },
    noFill: { type: Boolean, default: false },
    btnText: { type: String, default: "" },
    btnIcon: { type: String, default: "" },
    addBtn: { type: String, default: "إضافة" },
    editBtn: { type: String, default: "تعديل" },
    deleteBtn: { type: String, default: "حذف" },
    title: { type: String, default: "title" },
    size: { type: String, default: "lg" },
  },

  methods: {
    setEvent() {
      if (this.editMode) {
        this.$emit("edit");
      } else {
        this.$emit("add");
      }
    },
    deleteEvent() {
      this.$emit("delete");
    },
    showEvent() {
      this.$emit("beforeOpen");
    },
    closeEvent() {
      this.$emit("close");
    },
    open() {
      this.$refs["ek-modal"].show();
    },
    close() {
      this.$refs["ek-modal"].hide();
    },
  },
  mounted() {
    this.$emit("created");
  },
};
</script>

<style lang="scss">
.float-btn {
  position: fixed;
  bottom: 15%;
  right: 5%;
  z-index: 5000;
}
</style>
