import api from "@api";
import route from "@/router";
import { getSearchResultOnRow } from "@/EK-core/util";
export default {
    state: {
        isCode: true,
        subjectPackageList: [],
        unitsAndSubjects: [],
        codeStatusType: [
            { value: 1, text: "متاح", color: "success" },
            { value: 2, text: "منتهي الصلاحية", color: "warning" },
            { value: 3, text: "مباع", color: "primary" }
        ],
        packageStatusType: [
            { name: "ديناميكية", id: 1 },
            { name: "ستاتيكية", id: 2 }
        ],
        packageStatus: [
            { text: "مخفية", value: false, color: "danger" },
            { text: "فعال", value: true, color: "success" }
        ],

        infoPackage: [],
        codeList: [],
        packageList: [],
        codeCols: [
            {
                field: "code",
                label: "الرمز"
            },
            {
                field: "studentName",
                label: "اسم الطالب"
            },
            {
                field: "startDate",
                label: "تاريخ بداية الاشتراك "
            },
            {
                field: "sellPointName",
                label: "اسم المندوب"
            },
            {
                field: "endDate",
                label: " تاريخ نهاية الاشتراك"
            },
            {
                field: "price",
                label: "القيمة المدفوعة"
            },
            {
                field: "codeStatus",
                label: "حالة الاشتراك"
            }
        ],
        columnsPackage: [
            {
                field: "name",
                label: "اسم الحزمة"
            },
            {
                field: "codesCount",
                label: "عدد الرموز"
            },
            {
                field: "dateCreated",
                label: "تاريخ الانشاء "
            },
            {
                field: "type",
                label: "نوع الحزمة"
            },
            {
                field: "price",
                label: "كلفة الحزمة"
            },
            {
                field: "isHidden",
                label: "حالة الحزمة"
            },
            {
                field: "details",
                label: "تفاصيل"
            }
        ],
        dtoCode: {
            packageId: "",
            discount: 0
        },
        dtoPackage: {
            name: "",
            description: "",
            expiryPeriod: 0,
            isHidden: true,
            price: 0,
            type: 1,
            subjectsLimit: 0,
            subjectUnitsChapters: []
        }
    },
    mutations: {
        ISCODE_BTN(state, payload) {
            state.isCode = payload;
        },
        GET_ALL_CODE(state, payload) {
            state.codeList = payload;
        },
        ADD_CODE(state, payload) {
            state.codeList.unshift(payload);
        },
        RESET_CODE_DTO(state) {
            state.dtoCode = {
                packageId: "",
                discount: 0
            };
        },
        SET_DTO_SUBJECTS(state, payload) {
            state.dtoPackage.subjectUnitsChapters = state.subjectPackageList.map(
                subj => {
                    let selected = false;

                    let units = subj.units.map(unt => ({
                        ...unt,
                        selected: false
                    }));

                    if (payload) {
                        const found = payload.find(
                            s => s.subjectId == subj.subjectId
                        );

                        if (found) {
                            selected = true;
                            units = units.map(un => ({
                                ...un,
                                selected: found.units.some(
                                    u => u.unitId == un.id
                                )
                            }));
                        }
                    }

                    return {
                        subjectId: subj.subjectId,
                        subjectName: subj.subjectName,
                        selected,
                        units
                    };
                }
            );
        },
        FILTER_SELECTED_SUBJECTS(state) {
            state.unitsAndSubjects = state.dtoPackage.subjectUnitsChapters
                .filter(s => {
                     return s.selected;
                })
                .map(s => {
                    return{

                        subjectId: s.subjectId,
                        
                        units: s.units.filter(u => u.selected)
                        .map(u => {
                            return u.id;
                        })
                        
                    }
                });
        },

        DELETE_CODES(state) {
            state.dtoPackage.subjectUnitsChapters = state.subjectPackageList.map(
                su => ({
                    subjectId: su.subjectId,
                    subjectName: su.subjectName,
                    selected: state.dtoPackage.some(s => s.id == su.id),
                    units: su.units.map(u => ({ ...u, selected: false }))
                })
            );
        },
        GET_ALL_PACKAGE(state, payload) {
            state.packageList = payload;
        },

        GET_INFO_PACKAGE(state, payload) {
            state.infoPackage = payload;
        },

        RESET_PACKAGE_DTO(state) {
            state.dtoPackage = {
                name: "",
                description: "",
                expiryPeriod: 0,
                isHidden: true,
                price: 0,
                type: 0,
                subjectsLimit: 0,
                subjectUnitsChapters: []
            };
        },

        ADD_PACKAGE(state, payload) {
            state.packageList.unshift(payload);
        },
        GET_BYID_PACKAGE(state, payload) {
            state.dtoPackage = {
                ...payload,
                expiryPeriod: `${payload.expiryPeriod} اشهر`
            };
        },
        GET_SUBJECT_PACKAGE(state, payload) {
            state.subjectPackageList = payload;
        },
        UPDATE_PACKAGE(state, payload) {
            state.dtoPackage = {
                ...payload,
                expiryPeriod: payload.expiryPeriod.replace("اشهر", "").trim()
            };
        },
        DELETE_PACKAGE(state, payload) {
            state.packageList = state.packageList.filter(
                ele => ele.id != payload
            );
            route.push("/admin/activationCode");
        },
        DELETE_PACKAGES(state, paylaod) {
            state.packageList = state.packageList.filter(
                ele => !paylaod.includes(ele.id)
            );
        }
    },
    actions: {
        getAllCode({ commit }) {
            api.get("Code/Dash/GetAll", ({ data }) => {
                commit("GET_ALL_CODE", data);
            });
        },
        addCode({ commit }, payload) {
            api.post(
                "Code/Dash/Add",
                payload,
                ({ data }) => {
                    commit("ADD_CODE", data);
                },
                {
                    success: "تم اضافة هذا الرمز بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },

        deleteCodes({ commit }, ids) {
            api.delete(
                `Code/Dash/DeleteRenge`,
                () => {
                    commit("DELETE_CODES", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف هذه الرموز المحددة",
                    success: "تم حذف هذه الرموز بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },
        getAllPackage({ commit }) {
            api.get("Package/Dash/GetAll", ({ data }) => {
                commit("GET_ALL_PACKAGE", data);
            });
        },
        getInfoPackage({ commit }) {
            api.get("Package/Dash/GetBaseInfo", ({ data }) => {
                commit("GET_INFO_PACKAGE", data);
            });
        },

        addPackage({ commit }, payload) {
            api.post(
                "Package/Dash/Add",
                payload,
                ({ data }) => {
                    commit("ADD_PACKAGE", data);
                    route.go(-1);
                },
                {
                    success: "تم اضافة هذه الحزمة بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },
        getPackageById({ commit }, id) {
            return new Promise(res => {
                api.get(`Package/Dash/GetById?id=${id}`, ({ data }) => {
                    commit("GET_BYID_PACKAGE", data);
                    res(data);
                });
            });
        },

        getSubjectPackage({ commit }) {
            return new Promise(res => {
                api.get("Package/Dash/GetAllAvailableSubject", ({ data }) => {
                    res(data);
                    commit("GET_SUBJECT_PACKAGE", data);
                });
            });
        },
        deletePackage({ commit }, id) {
            api.delete(
                `Package/Dash/Delete?id=${id}`,
                ({ data }) => {
                    commit("DELETE_PACKAGE", data);
                    route.push("/admin/activationCode");
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحزمة المحددة",
                    success: "تم حذف هذه الحزمة بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                }
            );
        },
        deletePackages({ commit }, ids) {
            api.delete(
                "Package/Dash/DeleteRenge",
                () => {
                    commit("DELETE_PACKAGES", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحزم المحددة",
                    success: "تم حذف هذه الحزم بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },
        updatePackage({ commit }, payload) {
            api.post(
                "Package/Dash/Modify",
                {
                    ...payload,
                    expiryPeriod: payload.expiryPeriod
                        .replace("اشهر", "")
                        .trim()
                },
                ({ data }) => {
                    route.push("/admin/activationCode");
                    commit("UPDATE_PACKAGE", data);
                },
                {
                    success: "تم تعديل هذه الحزمة بنجاح",
                    error: "فشل تعديل ..يرجى اعادة المحاولة "
                }
            );
        }
    },
    getters: {
        resultCodeList({ codeList }, getters, { filter }) {
            let query = filter.searchDto.query;
            return codeList.filter(ele => {
                return (
                    getSearchResultOnRow(filter, ele) || ele.codeStatus == query
                );
                // ele.name.indexOf(query) != -1 ||
                // ele.studentName.indexOf(query) != -1 ||
                // ele.sellPointName.indexOf(query) != -1
            });
        }
    }
};
