import Vue from 'vue';
export const EventBus = new Vue();

export function handleLangChange(action) {
    EventBus.$on('onLangChange', (id)=>{ 
        action(id);      
    });
    
}
export function handleChangeUserInfo(action) {
    EventBus.$on('onChangeUserInfo', ()=>{ 
        action();      
    });
}