<template>
    <div>
        <slot name="activator">
            <activaitor @ok="is = true" @search="search" :placeholder="placeholder" :btnText="btnText" />
        </slot>
        <b-sidebar :class="skined.isDark ? 'darkTable' : 'lightTable'" backdrop shadow v-model="is"
            @hidden="setCloseEvent">
            <template #header>
                <div class="d-flex justify-content-between align-items-center px-1 py-50  border-bottom">
                    <slot name="header">
                        <h5 class="m-0">
                            {{ title }}
                        </h5>
                    </slot>
                    <b-button variant="flat-secondary" size="sm" class="rounded-circle btn-icon ml-auto"
                        @click="is = false">
                        <unicon name="times" width="14" height="14" />
                    </b-button>
                </div>
            </template>
            <template #default>
                <slot name="body"> </slot>
            </template>
            <template #footer="{ hide }">
                <slot name="footer">
                    <div class="d-flex align-items-center px-1 py-1 border-top">
                        <b-button variant="outline-danger" v-if="isEdit" @click="del">حذف</b-button>
                        <b-button variant="outline-secondary" class="ml-auto mr-50" @click="hide">إلغاء</b-button>
                        <b-button variant="primary" @click="ok">{{
                                isEdit ? "تعديل" : "حفظ"
                        }}</b-button>
                    </div>
                </slot>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import activaitor from "./activaitor";
import { BButton, BSidebar } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
export default {
    components: {
        activaitor,
        // bootstrap vue
        BButton,
        BSidebar
    },
    computed: {
        skined() {
            const { skin } = useAppConfig();
            const isDark = skin.value === "dark";
            return { skin, isDark };
        }
    },
    data: () => ({
        is: false
    }),
    props: {
        placeholder: String,
        btnText: String,
        title: {
            type: String,
            default: () => "title"
        },
        endClose: Boolean,
        isEdit: {
            type: Boolean,
            default: () => false
        }
    },
    methods: {
        ok() {
            this.$emit("ok");
            if (this.endClose) this.is = false;
        },
        del() {
            this.$emit("delete");
            this.is = false;
        },

        search(e) {
            this.$emit("search", e);
        },
        open() {
            this.is = true;
        },
        close() {
            this.is = false;
        },
        setCloseEvent() {
            this.$emit("close");
        }
    },
    watch: {
        is(is) {
            if (is) {
                this.$emit("open");
            } else {
                this.$emit("close");
            }
        }
    }
};
</script>

<style lang="scss">
.b-sidebar-header {
    padding: 0 !important;
    display: block !important;
}

.b-sidebar-body {
    padding: 12px;
}

b-sidebar-outer.darkTable {

    .b-sidebar-header,
    .b-sidebar-body,
    .b-sidebar-footer {
        background-color: #283046 !important;
    }
}

b-sidebar-outer.lightTable {
    background-color: transparent !important;
}
</style>
