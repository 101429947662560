import api from "@api";
import router from "@/router";
import { nullGuid } from "@Ekcore/util/global.js";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        notifications: [],
        notificationDto: {
            id: nullGuid,
            title: "",
            body: "",
            sendTo: "",
            date: "",
        },
        searchDto: {
            keys: [],
            query: "",
        },
    },
    getters:{
        notificationsList({ notifications }, getter , { filter } ) { 
            return  notifications.filter(el => {
                return getSearchResultOnRow(filter, el) 
            });
        },
    },
    mutations: {
        Get_Notification_List(state, payload) {
            state.notifications = payload 
        },
        Reset_Notifications_Dto(state) {
            Object.assign(state.notifications, {
                id: nullGuid,
                title: "",
                body: "",
                sendTo: 1,
                date: "",
            });
        },
        Add_Notifications(state, payload) {
            state.notifications.unshift(payload);
        },
        Update_Notifications(state, payload) {
            state.notificationDto = { ...payload };
        },
        Delete_Notification(state, id) {
            state.notifications.splice(
                state.notifications.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Notifications(state, ids) {
            state.notifications = state.notifications.filter(
                (el) => !ids.includes(el.id)
            ); 
        },
        Set_Notification_Dto(state, payload) {
            if (payload) Object.assign(state.notificationDto, payload);
            else {
                state.notificationDto = {
                    id: nullGuid,
                    title: "",
                    body: "",
                    sendTo: "",
                    date: "",
                };
            }
        },
    },
    actions: {
        getNotificationsList({ commit }) {
            api.get("Notification/Dash/GetAll", ({ data }) => {
                commit("Get_Notification_List", data);
                this.commit("Set_Notification_Dto");
            });
        },
        getNotificationDetails({ commit }, id) {
            api.get(`Notification/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Update_Notifications", data);
            });
        },
        addNotifications({ commit }, payload) {
            api.post(
                "Notification/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Notifications", data);
                    commit("Reset_Notifications_Dto");
                },
                {
                    success: "تم إضافة الإشعار بنجاح",
                    error: "فشل إضافة الإشعار",
                }
            );
        },
        deleteNotifications({ commit }, id) {
            api.delete(
                `Notification/Dash/Delete?id=${id}`,
                () => {
                    commit("Delete_Notification", id);
                    router.push("/admin/notifications");
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الإشعار؟",
                    success: "تم حذف الإشعار بنجاح",
                    error: "فشل حذف الإشعار ..يرجى اعادة المحاولة ",
                }
            );
        },
        deleteNotificationsRang({ commit }, ids) {
            api.delete(
                "Notification/Dash/DeleteRange",
                () => {
                    commit("Delete_Notifications", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاشعارات المحددة",
                    success: "تم حذف الاشعارات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
