import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";
import router from "@/router";
import { getUserData  , getUserRoles} from "@/app/admin/auth/utils";
import { SuperAdmin } from "@/router";


export default {
    state: {
        teacherInvoice: [],
        teachersDetails: [],
        teacherUnpaid:[],
        cols: [
            {
                label: " اسم الإستاذ",
                field: "name",
            },
            {
                label: "عدد الإشتراكات",
                field: "countSubscriptions",
            },
            {
                label: "تاريخ آخر مطابقة",
                field: "lastAccordanceDate",
            },
            {
                label: "صافي االارباح ",
                field: "netProfit",
            },
            {
                label: "الرصيد المستحق له",
                field: "teacherPaid",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],
        DetailteacherCols: [
            {
                label: "رقم الفاتورة ",
                field: "serialNumber",
            },
            {
                label: " التاريخ",
                field: "date",
            },
            {
                label: "  عدد الإشتراكات",
                field: "countSubscription",
            },
            {
                label: " قيمة الفاتورة ",
                field: "value",
            },
            {
                label: "التفاصيل",
                field: "details",
            },
        ],

    },
    getters:{
        teacherUnpaid:(state)=>state.teacherUnpaid,
        teacherInvoiceList({ teacherInvoice }, getters, { filter }) {
            let query = filter.searchDto.query;
            
            if(query){
           return teacherInvoice.filter(el => {
               return (
                  ( getSearchResultOnRow(filter, el) ||
                   el.name.indexOf(query) != -1 ) && (el.id == getUserData().id || getUserRoles() == SuperAdmin)
               );
           });
        }else{
            return teacherInvoice;
        }
       },
        teacherDetailsList(state) {
            return state.teachersDetails.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
        teacherUnpaidList(state) {
            return state.teacherUnpaid.filter((serv) => {
                return getSearchResultOnRow(state, serv);
            });
        },
    },
    mutations: {
        Get_Teachers_List(state, payload) {
            state.teacherInvoice = payload 
        },
        Get_Details_Teacher_List(state, payload) {
            state.teachersDetails = payload
        },
        Get_Unpaid_Teacher_List(state, payload) {
            state.teacherUnpaid = payload 
            console.log('teest1', payload)

        },
    },
    actions: {
        getInvoiceTeacherList({ commit }) {
            api.get("Invoice/Dash/GetAllTeacher",
            ({ data }) => {
                 console.log("teacher", data)
                commit("Get_Teachers_List", data)
            });
        },
        getDetailsTeacherList({ commit }, id) {
            api.get(`Invoice/Dash/GetDetailsTeacher?id=${id}`, 
            ({ data }) => {
                commit("Get_Details_Teacher_List", data)
            });
        },
        getUnpaidTeacherList({ commit }, id) {
            return new Promise(resolve => {
                api.get(`Invoice/Dash/GetUnpaidByTeacherId?id=${id}`, 
                ({ data }) => {
                    commit("Get_Unpaid_Teacher_List", data)
                    console.log('teest', data)
                    resolve(data)
                });
            })
            
        },
        addTeacher(_, payload) {
            return new Promise(resolve => {
                api.post(
                    "Invoice/Dash/AddInvoiceToTeacher",
                    payload,
                    ({ data }) => {
                        resolve(data)
                        router.push(router.go(-1))
                    },
                    {
                        success: "تم إضافة الفاتورة بنجاح",
                        error: "فشل إضافة الفاتورة",
                    }
                );
            })
            
        },
        getTeacherInvoiceDetails(_, id) {
            return new Promise(resolve => {
                api.get(`Invoice/Dash/GetDetailsInvoiceTeacher?id=${id}`,
                    ({ data }) => {
                        resolve(data)
                    }
                );
            })
           
        },
    },
};