import api from "@api";
import { getSearchResultOnRow } from "@/EK-core/util";
// import router  from "@/router";
export default {
    state: {
        classList: [],
        classesOptions: [],
        isDialogOpen: false,
        educationLevelList: [
            { id: 1, name: "المرحلة الابتدائية" },
            { id: 2, name: "المرحلة الاعدادية" },
            { id: 3, name: "المرحلة الثانوية" }
        ],

        classDto: {
            name: "",
            educationalLevel: 1,
            order: 0,
            colors: []
        },
        classDetailsDto: {
            name: "",
            order: 0,
            subjects: [
                {
                    name: "",
                    dateCreated: "",
                    semester: 0,
                    unitCount: 0,
                    teacherCount: 0,
                    lessonCount: 0,
                    studentsCount: 0
                }
            ]
        },
        columns: [
            {
                label: " اسم المادة",
                field: "name"
            },
            {
                label: "تاريخ الاضافة",
                field: "dateCreated"
            },
            {
                label: "الفصل",
                field: "semester"
            },
            {
                label: " عدد الوحدات",
                field: "unitCount"
            },
            {
                label: "عدد الدروس",
                field: "lessonCount"
            },
            {
                label: "عدد المدرسين",
                field: "teacherCount"
            },
            {
                label: "عدد الطلاب",
                field: "studentsCount"
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false
            }
        ]
    },
    mutations: {
        SET_ALL_CLASS(state, payload) {
            state.classList = payload;
        },
        SET_DETAIL_CLASS(state, payload) {
            state.classDetailsDto = { ...payload };
        },
        SET_CLASSES_LIST(state, payload) {
            state.classesOptions = [...payload];
        },
        ADD_CLASS(state, payload) {
            state.classList.unshift(payload);
        },
        DELETE_CLASS(state, payload) {
            state.classList = state.classList.filter(ele => ele.id != payload);
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        SET_CLASS_DTO(state, payload) {
            if (payload) state.classDto = { ...payload };
            else
                state.classDto = {
                    name: "",
                    colors: [],
                    educationalLevel: "",
                    order: 0
                };
        },
        UPDATE_CLASS(state, payload) {
            let dto = state.classList.find(ele => ele.id == payload.id);
            Object.assign(dto, payload);
        }
    },
    actions: {
        getClasses({ commit }) {
            api.get("Syllabus/Dash/GetAllClass", ({ data }) => {
                commit("SET_ALL_CLASS", data);
            });
        },
        getByIdClass({ commit }, id) {
            api.get(`Syllabus/Dash/GetByIdClass?id=${id}`, ({ data }) => {
                commit("SET_DETAIL_CLASS", data);
            });
        },
        getClassesList({ commit }) {
            api.get("Syllabus/Dash/GetClassesId", ({ data }) => {
                commit("SET_CLASSES_LIST", data);
            });
        },
        addClass({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                api.post(
                    "Syllabus/Dash/AddClass",
                    payload,
                    res => {
                        dispatch("getClassesList");
                        commit("ADD_CLASS", res.data);
                        commit("SET_CLASS_DTO");
                        resolve(res);
                    },
                    {
                        success: "تم اضافة الصف بنجاح",
                        error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        deleteClass({ commit }, id) {
            api.delete(
                `Syllabus/Dash/DeleteClass?id=${id}`,
                () => {
                    commit("DELETE_CLASS", id);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الصف المحدد",
                    success: "تم حذف الصف بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                }
            );
        },
        updateClass({ commit }, payload) {
            api.post(
                "Syllabus/Dash/ModifyClass",
                payload,
                ({ data }) => {
                    commit("UPDATE_CLASS", data);
                    commit("SET_CLASS_DTO");
                },
                {
                    // confirm: " ?هل تريد بالتأكيد حذف الصف المحدد",
                    success: "تم تعديل الصف بنجاح",
                    error: "فشل التعديل ..يرجى اعادة المحاولة "
                }
            );
        }
    },
    getters: {
        classesList({ classList, educationLevelList }, getters, { filter }) {
            let query = filter.searchDto.query;
            return classList.filter(ele => {
                return (
                    ele.name.indexOf(query) != -1 ||
                    educationLevelList
                        .find(item => item.id == ele.educationalLevel)
                        .name.indexOf(query) != -1 ||
                    ele.studentsCount == query ||
                    ele.subjectsCount == query
                );
            });
        },
        classSubList({ classDetailsDto }, getters, { filter }) {
            return classDetailsDto.subjects.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        }
    }
};
