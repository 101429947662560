import api from "@api";
import router from "@/router";
export default {
    state: {
        columns: [
            {
                label: " الاسم ",
                field: "name"
            },
            {
                label: "رقم الموبايل",
                field: "phoneNumber"
            },
            {
                label: "تاريخ الانشاء",
                field: "dateCreated"
            },
            {
                label: "حالة",
                field: "isBlocked"
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false
            }
        ],
        dashUserList: [],
        dashUserStatus: [
            { value: false, text: "فعال", color: "success" },
            { value: true, text: "غير فعال", color: "danger" }
        ],
        dashUserRole: [
            { name: "مدخل بيانات", id: 2 },
            { name: "ادمن", id: 1 }
        ],

        dtoDetailsDashUser: {
            id: "",
            name: "",
            email: "",
            newPassword: "",
            phoneNumber: null,
            birthDate: "",
            registrationDate: "",
            isBlocked: false,
            role: 0
        }
    },
    mutations: {
        Get_Dash_User(state, payload) {
            state.dashUserList = payload;
        },
        Get_Dash_User_ById(state, payload) {
            state.dtoDetailsDashUser = { ...payload, newPassword: "" };
        },
        Reset_User_Dto(state) {
            state.dtoDetailsDashUser = {
                id: "",
                name: "",
                email: "",
                password: "",
                phoneNumber: null,
                birthDate: "",
                registrationDate: "",
                role: 1
            };
        },
        Add_Dash_User(state, payload) {
            state.dashUserList.push(payload);
        },
        Update_Dash_User(state, payload) {
            Object.assign(state.dtoDetailsDashUser, payload);
        },
        Delete_Dash_User(state, payload) {
            state.dashUserList = state.dashUserList.filter(
                ele => ele.id != payload
            );
            router.push("/admin/appPanel");
        },
        Delete_Dash_Users(state, payload) {
            state.dashUserList = state.dashUserList.filter(
                el => !payload.includes(el.id)
            );
        },

        IsBlocked_Dash_User(state, payload) {
            state.dtoDetailsDashUser.isBlocked = payload;
        }
    },
    actions: {
        getDashUser({ commit }) {
            api.get("DashUser/GetAllDash", ({ data }) => {
                commit("Get_Dash_User", data);
            });
        },
        getDashUserById({ commit }, id) {
            api.get(`DashUser/GetById?id=${id}`, ({ data }) => {
                commit("Get_Dash_User_ById", data);
            });
        },
        addDashUser({ commit }, payload) {
            api.post(
                "DashUser/Add",
                payload,
                ({ data }) => {
                    commit("Add_Dash_User", data);
                },
                {
                    // confirm: " ?هل تريد بالتأكيد حذف الحساب المحدد",
                    success: "تم اضافة الحساب بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },
        deleteDashUser({ commit }, id) {
            api.delete(
                `DashUser/Delete?id=${id}`,
                ({ data }) => {
                    commit("Delete_Dash_User", data);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحساب المحدد",
                    success: "تم حذف الحساب بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                }
            );
        },
        deleteRangeDashUser({ commit }, ids) {
            api.delete(
                `DashUser/DeleteRange`,
                () => {
                    commit("Delete_Dash_Users", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحسابات المحددة",
                    success: "تم حذف الحسابات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },
        updateDashUser({ commit }, payload) {
            api.post(
                "DashUser/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Dash_User", data);
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات المستخدم؟",
                    success: "تم تعديل المستخدم بنجاح",
                    error: "فشل التعديل.. يرجى إعادة المحاولة"
                }
            );
        },
        blockDashUser({ commit }, id) {
            api.post(
                `User/Dash/Block?id=${id}`,
                null,
                () => {
                    commit("IsBlocked_Dash_User", true);
                },
                {
                    success: "تم حظر المستخدم بنجاح",
                    error: "فشل حظر المستخدم ..يرجى اعادة المحاولة "
                }
            );
        },
        unBlockDashUser({ commit }, id) {
            api.post(
                `User/Dash/UnBlock?id=${id}`,
                null,
                () => {
                    commit("IsBlocked_Dash_User", false);
                },
                {
                    success: "تم الغاء حظر المستخدم بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة "
                }
            );
        }
    },
    getters: {}
};
